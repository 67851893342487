<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="終了報告済マニフェスト" />
    <AppSideMenu />
    <main class="l-main">
      <ToastMessage :toastMsg.sync="toastMsg" />
      <!-- 検索フォーム ============================================================ -->
      <SearchForm
        :manifestStatusCount="manifestStatusCount"
        @search="getManifestSearchApi"
      />

      <!-- 検索結果 ============================================================ -->
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />

        <div v-if="manifestList.length" class="l-flex cols l-item" :class="{ disabled: isCallApiProcess }">
          <div class="u-txt-bold">選択中 {{ checkCount }} 件</div>
          <div class="l-flex cols" :class="{ disabled: !checkCount }">
            <div class="c-btn primary small" @click="showChecksheetDownloadModal">受渡確認票印刷</div>
          </div>
          <div class="c-btn secondary small upload" style="margin-left: auto;!important;width: 16rem;" @click="onClickCsvUploadModalDisplayButton">CSVアップロード</div>
          <div class="c-btn secondary small download" style="width: 16rem;" @click="$refs.csvdownloadmodal.updateCSVModal(true)">CSVダウンロード</div>
        </div>

        <div class="c-infoList__wrap">
          <div class="c-infoList scroll l-box">
            <div class="c-infoList__row head">
              <div class="c-infoList__cell cb">
                <div class="c-checkbox">
                  <input
                    id="check-all-manifests"
                    type="checkbox"
                    v-model="isAllChecked"
                    @change="changeAllCheckHandler"
                  />
                  <label class="c-checkbox__label" for="check-all-manifests">
                    <span class="c-checkbox__box"></span>
                  </label>
                </div>
              </div>
              <div class="c-infoList__cell sm">マニフェスト番号</div>
              <div class="c-infoList__cell sm">ステータス</div>
              <div class="c-infoList__cell md-s">
                <a @click="clickSortChangeHandler('collection_date')">
                    <span class="c-infoList__cell__sort" :class="{
                        asc: sortParams.type.toLowerCase() === 'asc' && sortParams.column === 'collection_date',
                        desc: sortParams.type.toLowerCase() === 'desc' && sortParams.column === 'collection_date',
                        'sort-disabled': sortParams.column !== 'collection_date',
                      }"
                    >引き渡し日</span>
                </a>
              </div>
              <div class="c-infoList__cell md-s">
                <a @click="clickSortChangeHandler('collection_report_date')">
                    <span class="c-infoList__cell__sort" :class="{
                        asc: sortParams.type.toLowerCase() === 'asc' && sortParams.column === 'collection_report_date',
                        desc: sortParams.type.toLowerCase() === 'desc' && sortParams.column === 'collection_report_date',
                        'sort-disabled': sortParams.column !== 'collection_report_date',
                      }"
                    >運搬終了日（報告日）</span>
                </a>
              </div>
              <div class="c-infoList__cell sm">廃棄物名称</div>
              <div class="c-infoList__cell sm">詳細名称</div>
              <div class="c-infoList__cell sm">数量</div>
              <div class="c-infoList__cell sm">排出業者</div>
              <div class="c-infoList__cell sm">車両番号</div>
              <div class="c-infoList__cell sm">運搬区間</div>
            </div>

            <!-- 検索結果あり -->
            <template v-if="manifestList.length">
              <div
                v-for="manifest of manifestList"
                :key="manifest.manifestNo"
                :to="`/generator/contract/${manifest.id}`"
                class="c-infoList__row list"
              >
                <div class="c-infoList__cell cb text-dotted">
                  <div class="c-checkbox">
                    <input
                      :id="manifest.manifestId"
                      type="checkbox"
                      v-model="manifest.isChecked"
                      @change="changeManifestCbHandler(manifest.isChecked)"
                    />
                    <label class="c-checkbox__label" :for="manifest.manifestId">
                      <span class="c-checkbox__box"></span>
                    </label>
                  </div>
                </div>
                <div @click="toDetail(manifest.manifestId)" class="c-infoList__cell sm text-dotted">
                  <router-link :to="`/manifest/${manifest.manifestId}`">
                    {{ manifest.manifestNo | blankText }}
                  </router-link>
                </div>
                <!-- ステータス -->
                <div @click="toDetail(manifest.manifestId)" class="c-infoList__cell sm text-dotted">
                  {{ manifest.manifestStatusType == null ? "": manifest.manifestStatusType.name }}
                </div>
                <!-- 引き渡し日 -->
                <div class="c-infoList__cell md-s full-date c-tooltip" @click="toDetail(manifest.manifestId)">
                  <div class="c-infoText">
                    <div>
                      <template v-if="manifest.isEdit">
                        <InputDate class="inline-edit-mode"
                            :value.sync="manifest.editCollectionDate"
                            placeholder="0000/00/00"
                            :validation="validateJustBlank"
                            :isValid.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editCollectionDate.isValid"
                            :errMsg.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editCollectionDate.errMsg"
                        />
                      </template>
                      <template v-else>
                        {{ manifest.collectionDate | dateJpMDddFormat }}
                      </template>
                    </div>
                  </div>
                  <div v-if="manifest.collectionDateLimit" class="c-status" :class="manifest.collectionDateLimit">
                    <div class="c-infoList__popup">
                      <span v-if="manifest.collectionDateLimit === limitStatus.INFO">交付時は入力が必要です<br></span>
                      <span v-if="manifest.collectionDateLimit === limitStatus.WARNING">交付期限が迫っています<br></span>
                      <span v-if="manifest.collectionDateLimit === limitStatus.DANGER">交付期限を超過しています<br></span>
                    </div>
                  </div>
                </div>

                <!-- 運搬終了日（報告日） -->
                <div class="c-infoList__cell md-s c-tooltip" @click="toDetail(manifest.manifestId)">
                  <div class="c-infoText">
                    <div>
                      {{ manifest.collectionReportDate | dateJpMDddFormat }}
                    </div>
                  </div>
                  <div v-if="manifest.isExpiredCollectionDate === 1" class="c-status danger">
                    <div class="c-infoList__popup">
                      運搬終了報告期限を超過しています
                    </div>
                  </div>
                </div>
                <div class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
                  {{ manifest.wasteName === null ? "" : manifest.wasteName.name }}
                </div>
                <div class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
                  {{ manifest.detailWasteName === null ? "" : manifest.detailWasteName }}
                </div>
                <div class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
                  {{ (manifest.wasteQuantity === null ? "" : manifest.wasteQuantity) + (manifest.wasteUnitType === null ? "" : manifest.wasteUnitType.name) }}
                </div>
                <div class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
                  {{ manifest.generateCompany === null ? "" : manifest.generateCompany.name }}
                </div>
                <!-- 車両番号 -->
                <div class="c-infoList__cell sm c-tooltip" @click="toDetail(manifest.manifestId)">
                  <CommaText :items="manifest.carNoList" :maxLength="11" :showblankText="true" popupSize="sm"/>
                </div>
                <!-- 運搬区間-->
                <div class="c-infoList__cell sm c-tooltip" @click="toDetail(manifest.manifestId)">
                  <div class="c-infoText">
                    <div class="c-infoText__barWrap">
                      <span
                        v-for="(cc, index) in manifest.collectCompany.length"
                        :key="`ccindex-${index}`"
                        class="c-infoText__bar"
                        :class="{'active': !!manifest.collectCompany[index].collectionReportDate }"
                      ></span>
                    </div>
                  </div>
                  <div v-if="manifest.collectCompany.length" class="c-status default">
                    <div class="c-infoList__popup check">
                      <ul class="c-checkList">
                        <li v-for="(cc, index) in manifest.collectCompany"
                            :key="'collect-company-' + index"
                            class="c-checkList__each mb"
                        >
                          <div>
                            <span
                              class="c-checkList__icon"
                              :class="{'active': !!manifest.collectCompany[index].collectionReportDate }"
                            ></span>
                          </div>
                          {{ cc.name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- 終了報告修正ボタンを押して終了報告修正画面に遷移 -->
                <div v-if="manifest.isAbleCollectionReportEdit && manifest.collectCompany[0].id === collectCompanyId" class="c-infoList__cell sm sticky" @click="toCollectionReportEdit(manifest.manifestId)">
                  <div>
                      <div class="c-btn secondary small">終了報告修正</div>
                  </div>
                </div>
              </div>
            </template>

            <!-- 検索結果なし -->
            <template v-else>
              <div class="c-infoList__row list">
                <div class="c-infoList__cell">
                  検索条件に一致するマニフェストはありません。
                </div>
              </div>
            </template>
          </div>
        </div>

        <Pagination v-if="manifestList.length"
            :params="paginationParams"
            :page.sync="paginationParams.page"
            @callback="getManifestSearchApi(searchedParam, true)"
        />
      </div>
    </main>

    <!-- 登録モーダル -->
    <div class="c-modal lg" v-show="isChecksheetDownloadModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">

        <div class="c-modal__head">
          <h5 class="c-lead-s">受渡確認票印刷ダウンロード</h5>
          <span class="c-modal__head__close" @click="closeModalHandler"></span>
        </div>
        <div class="c-modal__body">
          <p class="u-txt-center">受け渡し確認票をダウンロードする形式を選択してください</p>
          <p class="u-txt-center" v-if="checksheetDownloadMessage">{{ checksheetDownloadMessage }}</p>

          <div
          v-for="cdt in checksheetDownloadTypes"
          :key="cdt.id"
          class="c-modal__foot"
          >
            <div>
              <div class="c-btn primary download-sm" :class="{ disabled: isDisableChecksheetDownload }" @click="checksheetDownloadHandler(cdt.value)">{{ cdt.label }}</div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div class="c-btn secondary small" @click="closeModalHandler">閉じる</div>
        </div>
      </div>
    </div>
    <AppFooter />

    <ManifestCsvUploadModal v-if="isDisplayCsvUploadModal" 
      v-bind:onClickCloseButton="hideCsvUploadModal"
      v-bind:onClickUploadButton="onClickUploadCsv"
      v-bind:onClickUploadRegisterCsvButton="onClickUploadRegisterCsv"
    />

    <CSVDownloadModal
      ref="csvdownloadmodal"
      @downloadCSVHandler="downloadCSVHandler"
    />

  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

import SearchForm from "./components/SearchForm.vue";
import Pagination from "@/components/parts/Pagination";
import search from "./mixins/search";
import { API_URL, DOMAIN } from "@/const";
import { saveAs } from "file-saver";
import {DATE_LIMIT_STATUS} from "@/const/dateLimit";
import ToastMessage from "@/components/parts/ToastMessage";
import manifestCallApi from "../../mixins/callApi";
import ManifestCsvUploadModal from "../../components/ManifestCsvUploadModal";
import CSVDownloadModal from "../../components/CSVDownloadModal";
import CommaText from "@/components/parts/CommaText";

export default {
  name: "manifest-collect-report-after",
  components: {
    SearchForm,
    Pagination,
    ToastMessage,
    ManifestCsvUploadModal,
    CSVDownloadModal,
    CommaText,
  },
  mixins: [search, manifestCallApi],
  data() {
    return {
      isSearchDetail: false,
      isAllChecked: false,
      checkCount: 0,
      manifestStatusCount: [],
      manifestList: [],
      // 検索内容別で持つ。ページ移動でもAPI叩くため
      searchedParam: {},
      // ページネーション用
      paginationParams: {
        page: 1,
        pageSize: 10,
        total: null,
      },
      sortParams: {
        column: "collection_date",
        type: "desc",
      },
      targetManifest: null,
      errMsgs: [],
      toastMsg: "",
      limitStatus: DATE_LIMIT_STATUS,
      isDisplayCsvUploadModal: false,
      isChecksheetDownloadModalShow: false,
      checksheetDownloadTypes: [
        {
          label: "PDFダウンロード(複数一括)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.BUNDLE_PDF,
        },
        {
          label: "PDFダウンロード(単票)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_ONE_SHEET,
        },
        {
          label: "PDFダウンロード(連票)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_THREE_SHEET,
        },
        {
          label: "EXCELダウンロード",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.EXCEL_ZIP,
        },
      ],
      isDisableChecksheetDownload: false,
      checksheetDownloadMessage: "",
      isCallApiProcess: false,
      collectCompanyId: this.$store.getters["userInfo/get"].groupInfo.id,
    };
  },
  methods: {
    closeModalHandler() {
      this.isChecksheetDownloadModalShow = false;
    },

    // 受渡確認票印刷押下
    showChecksheetDownloadModal() {
      this.isChecksheetDownloadModalShow = true;
    },

    // 受渡確認票をダウンロード
    checksheetDownloadHandler(downloadTypeId) {
      this.errMsgs = [];

      this.isDisableChecksheetDownload = true;
      this.checksheetDownloadMessage = `${this.checksheetDownloadTypes.find(t => t.value === downloadTypeId).label}を行なっています。少々お待ちください。`;
      axios
        .post(API_URL.MANIFEST.DELIVERY_CHECKSHEET
            , { downLoadTypeId: downloadTypeId, manifestIdList: this.getCheckedManifestIdList() }
            , { responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          if (downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_ONE_SHEET) {
            saveAs(blob, `受渡確認表(単票)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else if(downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_THREE_SHEET) {
            saveAs(blob, `受渡確認表(連票)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else if(downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.BUNDLE_PDF) {
            saveAs(blob, `PDFダウンロード(複数一括)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else {
            saveAs(blob, `受渡確認表_${moment().format("YYYYMMDDHHmmss")}.zip`);
          }
        })
        .then(() => {
          // ダウンロードボタンは活生にする
          this.isDisableChecksheetDownload = false;
          this.checksheetDownloadMessage = "";
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });      
    },

    // 終了報告修正画面に遷移する
    toCollectionReportEdit(manifestId) {
      const transitionSource = location.pathname;
      this.$router.push(`/manifest/collect/report/edit/${manifestId}?transitionSource=${transitionSource}`);
    },

    // 詳細画面に遷移
    toDetail(manifestId) {
      const transitionSource = location.pathname;
      this.$router.push(`/manifest/${manifestId}?transitionSource=${transitionSource}`);
    },

    // 廃棄物名区分を表示する: 2次フェース向け_廃棄物種類情報待ち
    getJwnetItemManifestType(jwnetItemType, manifestType) {
      let join = (jwnetItemType !== null && manifestType !== null) ? "/" : "";
      let jwnetItemTypeName = (jwnetItemType === null) ? "": jwnetItemType.name;
      if(manifestType === null) return null;
      let manifestTypeName;
      if (manifestType === null) {
        manifestTypeName = "";
      } else if (manifestType.id === DOMAIN.MANIFEST_TYPE.DIGITAL)
      if(manifestType.id === DOMAIN.MANIFEST_TYPE.DIGITAL) {
        manifestTypeName = "電子"
      } else {
        manifestTypeName = "紙"
      }
      return jwnetItemTypeName + join + manifestTypeName
    },

    // マニフェスト件数取得Api
    getManifestCountApi() {
      axios
        .get(API_URL.MANIFEST.SUMMARY_COLLECT + DOMAIN.MANIFEST_SEARCH_CONDITION.AFTER_REPORT)
        .then((res) => {
          this.manifestStatusCount = res.data.manifestsSummaryList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 報告日がある運搬を返す（配列）
    getActiveCollectCompany(collectCompanys) {
      let activeCompany = [];
      collectCompanys.map((cc) => {
        if (cc.collectionReportDate) {
          activeCompany.push(cc);
        }
      });
      return activeCompany;
    },

    // 運搬/処分の期限
    checkCollectDisposalReportLimit(date) {
      if (!date) return;
      const today = moment();
      const targetDate = moment(date);
      const daysDiff = targetDate.diff(today, "days");
      if (61 <= daysDiff && daysDiff <= 90) {
        return "warning";
      } else if (daysDiff >= 91) {
        return "danger";
      }
    },

    // 最終処分の期限
    checkFinalDisposalReportLimit(date) {
      if (!date) return;
      const today = moment();
      const targetDate = moment(date);
      const daysDiff = targetDate.diff(today, "days");
      if (121 <= daysDiff && daysDiff <= 180) {
        return "warning";
      } else if (daysDiff >= 181) {
        return "danger";
      }
    },

    // 期限ステータスチェック
    checkLimitStatus(date, issueAlertType) {
      if (!date) return DATE_LIMIT_STATUS.INFO;
      if (issueAlertType === 2) {
        return DATE_LIMIT_STATUS.DANGER;
      } else if (issueAlertType === 1) {
        return DATE_LIMIT_STATUS.WARNING;
      } else return DATE_LIMIT_STATUS.NOT_YET;
    },

    // マニフェスト全件チェックボックス
    changeAllCheckHandler() {
      if (this.isAllChecked) {
        this.manifestList.map((manifest) => {
          manifest.isChecked = true;
        });
      } else {
        this.manifestList.map((manifest) => {
          manifest.isChecked = false;
        });
      }
    },

    // 各マニフェストのチェックボックス
    changeManifestCbHandler(isChecked) {
      if (!isChecked) {
        this.isAllChecked = false;
      }
    },

    // ソート変更（引き渡し日、運搬終了報告日）
    clickSortChangeHandler(column) {
      if (this.sortParams.column !== column) {
        this.sortParams.type = "desc";
      } else {
        this.sortParams.type = this.sortParams.type.toLowerCase() === "asc" ? "desc" : "asc";
      }
      this.sortParams.column = column;

      this.getManifestSearchApi(this.searchedParam, false);
    },

    getCheckedManifestIdList() {
      const checkedManifests = this.manifestList.filter(
        (manifest) => manifest.isChecked
      );
      const manifestIdList = checkedManifests.map((manifest) => {
        return { manifestId: manifest.manifestId };
      });
      return manifestIdList;
    },
 
     /**
     * CSVフォーマット選択モーダル内ダウンロードボタン押下時
     */
    downloadCSVHandler(csvformatId) {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      axios
          .post(API_URL.MANIFEST.COLLECT_CSV_DOWNLOAD_BY_FORMAT
              , {
                manifestSearchFileDownloadInfoForCollector: this.getCSVDownloadSearchParamForCollector(),
                csvformatId: csvformatId
              }
              , { responseType: "blob" }
          )
          .then((res) => {
            const blob = new Blob([res.data], {
              type: res.data.type
            });
            saveAs(blob, `マニフェスト検索結果_${moment().format("YYYYMMDDHHmmss")}.csv`);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
          })
          .catch((err) => {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
            this.$refs.csvdownloadmodal.updateCSVModal(false);
          });
    },

    // 運搬用CSVダウンロード用の検索条件パラメーター
    getCSVDownloadSearchParamForCollector() {
      let generateStoreIdList = [];
      if (this.searchedParam.generateStoreIdList) {
        this.searchedParam.generateStoreIdList.forEach (
            generateStoreId => {
              if (generateStoreId.value !== "") {
                generateStoreIdList.push(generateStoreId.value);
              }
            }
        )
      }
      return {
        searchConditionId: 60,
        wasteName: this.searchedParam.wasteName === "" ? null : this.searchedParam.wasteName,
        generateCompanyId: this.searchedParam.generateCompanyId === "" ? null : this.searchedParam.generateCompanyId,
        manifestNo: this.searchedParam.manifestNo === "" ? null : this.searchedParam.manifestNo,
        collectionDateFrom: this.searchedParam.collectionDateFrom === "" ? null : this.searchedParam.collectionDateFrom,
        collectionDateTo: this.searchedParam.collectionDateTo === "" ? null : this.searchedParam.collectionDateTo,
        cbaItemId: this.searchedParam.cbaItemId === "" ? null : this.searchedParam.cbaItemId,
        jwnetItemTypeId: this.searchedParam.jwnetItemTypeId === "" ? null : this.searchedParam.jwnetItemTypeId,
        routeId: this.searchedParam.routeId === "" ? null : this.searchedParam.routeId,
        routeName: this.searchedParam.routeName === "" ? null : this.searchedParam.routeName,
        collectManifestSearchTypeIdList: this.searchedParam.status,
        generateStoreId: generateStoreIdList,
        disposalCompanyId: this.searchedParam.disposalCompanyId === "" ? null : this.searchedParam.disposalCompanyId,
        wasteTypeList: this.searchedParam.wasteType,
        jwnetItemTypeFieldList: this.searchedParam.jwnetItemTypeField,
        contactNo: this.searchedParam.contactNo === "" ? null : this.searchedParam.contactNo,
        remarks: this.searchedParam.remarks === "" ? null : this.searchedParam.remarks,
        isActive: this.searchedParam.isActive === false ? null : this.searchedParam.isActive,
        issueDateFrom: this.searchedParam.issueDateFrom === "" ? null : this.searchedParam.issueDateFrom,
        issueDateTo: this.searchedParam.issueDateTo === "" ? null : this.searchedParam.issueDateTo,
        collectionReportDateFrom: this.searchedParam.collectionReportDateFrom === "" ? null : this.searchedParam.collectionReportDateFrom,
        collectionReportDateTo: this.searchedParam.collectionReportDateTo === "" ? null : this.searchedParam.collectionReportDateTo,
        sort: this.sortParams.column + ":" + this.sortParams.type
      };
    },

    hideCsvUploadModal() {
      this.isDisplayCsvUploadModal = false;
    },
    onClickCsvUploadModalDisplayButton() {
      this.isDisplayCsvUploadModal = true;
    },

    onClickUploadCsv(csvFile) {
      return this.postManifestCsvupload(csvFile)
        .then(this.onUpload);
    },
    onClickUploadRegisterCsv(csvFile) {
      return this.postManifestCsvregister(csvFile)
        .then(this.onUpload);
    },

    onUpload() {
      this.hideCsvUploadModal();
      this.toastMsg = "正常にアップロードしました。";
      // 再検索を行う
      this.getManifestSearchApi(this.$store.getters["searchParamManifestsCollectReportAfter/get"], false);
    }
  },

  watch: {
    manifestList: {
      handler: function () {
        this.manifestList.forEach(manifest => {
          manifest.collectionDateLimit = this.checkLimitStatus(manifest.collectionDate, manifest.issueAlertType);
          manifest.carNoList = manifest.collectCompany.filter(cc => cc.carNo).map(collectCompany => collectCompany.carNo);
          manifest.isAbleCollectionReportEdit = (manifest.manifestStatusType.id >= DOMAIN.MANIFEST_STATUS.COLLECT_REPORTED 
                                                  && manifest.manifestStatusType.id !== DOMAIN.MANIFEST_STATUS.UNDER_COLLECT_REPORT_FIX
                                                  && manifest.manifestStatusType.id !== DOMAIN.MANIFEST_STATUS.COLLECT_REPORT_FIX_PENDING
                                                  && manifest.manifestStatusType.id !== DOMAIN.MANIFEST_STATUS.COMPLETE
                                                  && manifest.manifestStatusType.id !== DOMAIN.MANIFEST_STATUS.UNDER_DELETE
                                                  && manifest.manifestStatusType.id !== DOMAIN.MANIFEST_STATUS.DELETE)
        });

        this.checkCount = this.manifestList.filter(m => m.isChecked).length;
      },
      deep: true,
    },
  },

  beforeCreate() {
    // クエリパラメータのチェック
    if (this.$route.query.status || this.$route.query.collectionReportDateFrom || this.$route.query.clear) {
      this.$store.commit("searchParamManifestsCollectReportAfter/set", {
        wasteName: "",
        generateCompanyId: "",
        manifestNo: "",
        collectionDateFrom: "",
        collectionDateTo: "",
        cbaItemId: "",
        jwnetItemTypeId: "",
        routeId: "",
        routeName: "",
        issueDateFrom: "",
        issueDateTo: "",
        collectionReportDateFrom: this.$route.query.collectionReportDateFrom ? this.$route.query.collectionReportDateFrom : "",
        collectionReportDateTo: this.$route.query.collectionReportDateTo ? this.$route.query.collectionReportDateTo : "",
        status: this.$route.query.status ? this.$route.query.status.split(",").map(s => Number(s)) : [],
        generateStoreIdList: [
          {
            value: "",
          },
        ],
        disposalCompanyId: "",
        wasteType: [],
        jwnetItemTypeField:[],
        contactNo: "",
        remarks: "",
        isActive: true,
      });
    }
  },

  mounted() {
    this.getManifestCountApi();
    this.getManifestSearchApi(this.$store.getters["searchParamManifestsCollectReportAfter/get"], false);
  },
};
</script>
