import axios from "axios";
import { API_URL, DOMAIN } from "@/const";

export default {
  methods: {
    // URL生成
    getSearchApiUrl() {
      const params = new URLSearchParams();
      params.append("page", this.paginationParams.page);
      params.append("pageSize", this.paginationParams.pageSize);
      params.append("sort", `${this.sortParams.column}:${this.sortParams.type}`);
      params.append("searchConditionId", String(DOMAIN.MANIFEST_SEARCH_CONDITION.AFTER_REPORT));
      if (this.searchedParam.wasteName) params.append("wasteName", this.searchedParam.wasteName);
      if (this.searchedParam.generateCompanyId) params.append("generateCompanyId", this.searchedParam.generateCompanyId);
      if (this.searchedParam.manifestNo) params.append("manifestNo", this.searchedParam.manifestNo);
      if (this.searchedParam.collectionDateFrom) params.append("collectionDateFrom", this.searchedParam.collectionDateFrom);
      if (this.searchedParam.collectionDateTo) params.append("collectionDateTo", this.searchedParam.collectionDateTo);
      if (this.searchedParam.cbaItemId) params.append("cbaItemId", this.searchedParam.cbaItemId);
      if (this.searchedParam.jwnetItemTypeId) params.append("jwnetItemTypeId", this.searchedParam.jwnetItemTypeId);
      if (this.searchedParam.routeId) params.append("routeId", this.searchedParam.routeId);
      if (this.searchedParam.routeName) params.append("routeName", this.searchedParam.routeName);
      if (this.searchedParam.issueDateFrom) params.append("issueDateFrom", this.searchedParam.issueDateFrom);
      if (this.searchedParam.issueDateTo) params.append("issueDateTo", this.searchedParam.issueDateTo);
      if (this.searchedParam.collectionReportDateFrom) params.append("collectionReportDateFrom", this.searchedParam.collectionReportDateFrom);
      if (this.searchedParam.collectionReportDateTo) params.append("collectionReportDateTo", this.searchedParam.collectionReportDateTo);
      if (this.searchedParam.disposalCompanyId) { params.append("disposalCompanyId", this.searchedParam.disposalCompanyId); }
      if (this.searchedParam.contactNo) params.append("contactNo", this.searchedParam.contactNo);
      if (this.searchedParam.remarks) params.append("remarks", this.searchedParam.remarks);
      if (this.searchedParam.isActive) params.append("isActive", this.searchedParam.isActive); 
      if (this.searchedParam.wasteType) {
        this.searchedParam.wasteType.forEach((s) => {
          params.append("wasteTypeList", s)
        });
      }
      if (this.searchedParam.status) {
        this.searchedParam.status.forEach((s) => {
          params.append("collectManifestSearchType", s)
        });
      }
      if (this.searchedParam.generateStoreIdList) {
        this.searchedParam.generateStoreIdList.forEach(store => {
          if (store.value) {
            params.append("generateStoreId", store.value);
          }
        });
      }
      if (this.searchedParam.jwnetItemTypeField) {
        this.searchedParam.jwnetItemTypeField.forEach((s) => {
          params.append("jwnetItemTypeFieldList", s)
        });
      }

      return API_URL.MANIFEST.SEARCH_BY_COLLECT + '?' + params.toString();
    },

    // マニフェスト検索
    getManifestSearchApi(searchParam, isPaging) {
      this.isAllChecked = false;
      this.manifestList = [];
      if (!isPaging) {
        this.searchedParam = JSON.parse(JSON.stringify(searchParam)); // deep copy
      }
      return axios
        .get(this.getSearchApiUrl())
        .then((res) => {
          this.manifestList = res.data.results;
          this.paginationParams.total = res.data.searchParams.pagination.total;
          this.sortParams = res.data.searchParams.sorts[0];
          this.$store.commit("searchParamManifestsCollectReportAfter/set", this.searchedParam);
        })
        .then(() => {
          this.manifestList.map((manifest) => {
            this.$set(manifest, "isChecked", false);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
